//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import isEqual from 'lodash/isEqual'
import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'

export default {
  model: {
    prop: 'selected',
    event: 'change'
  },
  props: {
    selected: {
      type: [Array, String, Number],
      default: null
    },
    searchable: {
      type: Boolean,
      default: false
    },
    searchvalue: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: () => []
    },
    itemkey: {
      type: String,
      default: ''
    },
    resultitem: {
      type: String,
      default: ''
    },
    multiple: {
      type: Boolean,
      default: false
    },
    acceptance: {
      type: Boolean,
      default: false
    },
    closeOnSelect: {
      type: Boolean,
      default: true
    },
    hideDivider: {
      type: Boolean,
      default: false
    },
    gap: {
      type: String,
      default: '0px'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      show: false,
      localeSelected: cloneDeep(this.selected),
      tempSelected: cloneDeep(this.selected),
      searchterm: ''
    }
  },
  computed: {
    arrayType () {
      const typeList = this.list.reduce((p, c) => {
        let type
        if (Array.isArray(c)) {
          type = 'array'
        } else if (typeof c === 'object') {
          type = 'object'
        } else if (typeof c === 'string') {
          type = 'string'
        } else if (typeof c === 'number') {
          type = 'number'
        }
        if ((type && !p.includes(type)) || !type) {
          p.push(type)
        }
        return p
      }, [])
      if (typeList.length !== 1) {
        return undefined
      }
      return typeList[0]
    },
    searchableType () {
      return !['string', 'number'].includes(this.arrayType)
    },
    mixedContent () {
      return !!this.list.length && !this.arrayType
    },
    displayAble () {
      if (this.mixedContent) { return false }
      if (this.searchableType && !this.itemkey) { return false }
      return true
    },
    test () {
      return !!this.searchvalue
    },
    showSearch () {
      if (!this.searchable || this.mixedContent) { return false }
      if (this.searchableType && !this.searchvalue) { return false }
      return true
    },
    selectedItemIndexes () {
      const sel = this.acceptance ? 'tempSelected' : 'localeSelected'
      const compareHandler = this.searchableType ? this.compareObjects : this.compareNative
      return this.list.map((item) => {
        if (!this.multiple) {
          return compareHandler(item, this[sel])
        }
        if (!this[sel] || !Array.isArray(this[sel])) {
          return false
        }
        return this[sel].some(el => compareHandler(el, item))
      })
    },
    filteredList () {
      const filteredList = this.list.map((item, index) => ({ value: this.getValue(item), key: this.getKey(item), selected: this.selectedItemIndexes[index], item }))
      if (this.searchterm) {
        return filteredList.filter(item => item.value.toLowerCase().includes(this.searchterm.toLowerCase()))
      }
      return filteredList
    },
    buttonSlotSelected () {
      if (this.multiple) {
        return this.localeSelected?.length || 0
      }
      return this.localeSelected || ''
    }
  },
  watch: {
    localeSelected: {
      deep: true,
      handler (newVal) {
        this.$emit('change', newVal)
        this.handleCloseOnSelect()
      }
    },
    selected: {
      deep: true,
      handler (newVal) {
        if (isEqual(newVal, this.localeSelected)) { return }
        this.tempSelected = cloneDeep(newVal)
        this.localeSelected = cloneDeep(newVal)
      }
    },
    show (newVal) {
      if (newVal) {
        window.addEventListener('click', this.handleGlobalClick)
      } else {
        window.removeEventListener('click', this.handleGlobalClick)
      }
    }
  },
  beforeDestroy () {
    window.removeEventListener('click', this.handleGlobalClick)
  },
  methods: {
    updateOverlay () {
      this.$nextTick(() => {
        const overlay = this.$refs.overlay
        if (!overlay) { return }
        overlay.setContentHeight()
        overlay.setWindowHeight()
      })
    },
    handleReset () {
      this.localeSelected = null
      this.tempSelected = null
    },
    toggle () {
      if (this.show) {
        this.close()
      } else {
        this.open()
      }
    },
    open () {
      this.show = true
    },
    close () {
      this.searchterm = ''
      this.show = false
    },
    handleSelect (item) {
      if (this.multiple) {
        this.handleMultiSelect(item)
      } else {
        this.handleSingleSelect(item)
      }
    },
    handleMultiSelect (item) {
      const dest = this.acceptance ? 'tempSelected' : 'localeSelected'
      const compareHandler = this.searchableType ? this.compareObjects : this.compareNative
      if (!this[dest] || !Array.isArray(this[dest])) {
        this[dest] = [item]
        return
      }
      const index = this[dest].findIndex(elem => compareHandler(elem, item))
      if (index > -1) {
        this[dest].splice(index, 1)
      } else {
        this[dest].push(item)
      }
      if (!this[dest].length) {
        this[dest] = null
      }
    },
    handleSingleSelect (item) {
      const dest = this.acceptance ? 'tempSelected' : 'localeSelected'
      const compareHandler = this.searchableType ? this.compareObjects : this.compareNative
      this[dest] = compareHandler(this[dest], item) ? null : item
    },
    compareObjects (a, b) {
      return get(a, this.itemkey) === get(b, this.itemkey)
    },
    compareNative (a, b) {
      return a === b
    },
    getKey (item) {
      return this.searchableType ? get(item, this.itemkey) : item
    },
    getValue (item) {
      return this.searchableType ? get(item, this.searchvalue) : item
    },
    saveSelection () {
      this.localeSelected = cloneDeep(this.tempSelected)
      this.handleCloseOnSelect()
    },
    cancleSelection () {
      this.tempSelected = cloneDeep(this.localeSelected)
      this.handleCloseOnSelect()
    },
    handleCloseOnSelect () {
      if (this.closeOnSelect) {
        this.close()
      } else {
        this.updateOverlay()
      }
    },
    handleGlobalClick (event) {
      this.$nextTick(() => {
        const container = this.$refs.container
        if (!container) { return }
        if (container !== event.target && !container.contains(event.target)) {
          this.close()
        }
      })
    }
  }
}
