//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    minHeight: {
      type: Number,
      default: 150
    },
    height: {
      type: Number,
      default: 300
    },
    margin: {
      type: Number,
      default: 30
    },
    gap: {
      type: String,
      default: '0px'
    }
  },
  data () {
    return {
      windowHeight: -1,
      contentHeightFixed: 0,
      contentHeight: 0,
      style: {
        height: '0px',
        maxHeight: '0px',
        marginTop: this.gap
      }
    }
  },
  computed: {
    // only show if parent is component named 'Dropdown'
    hasDropdownParent () {
      return this.$parent?.$options?._componentTag === 'Dropdown'
    }
  },
  watch: {
    contentHeight (newVal) {
      this.style.height = newVal + 'px'
      this.style.maxHeight = newVal + 'px'
    },
    windowHeight () {
      const content = this.$refs.content
      if (!content) { return }
      this.$nextTick(() => {
        const mb = Math.round(this.windowHeight - content.getBoundingClientRect().bottom)
        if (mb < this.margin) {
          const temp = this.contentHeight - Math.abs(this.margin - Math.abs(mb))
          if (temp >= this.minHeight) {
            this.contentHeight = temp
          } else {
            this.contentHeight = this.minHeight
          }
          return
        }
        if (mb > this.margin) {
          const temp = (mb - this.margin) + this.contentHeight
          if (temp <= this.contentHeightFixed) {
            this.contentHeight = temp
          } else {
            this.contentHeight = this.contentHeightFixed
          }
        }
      })
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.setContentHeight()
      this.setWindowHeight()
      window.addEventListener('resize', this.handleResize)
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    setContentHeight () {
      this.$nextTick(() => {
        const header = this.$refs.header
        const body = this.$refs.body
        const footer = this.$refs.footer
        if (!header || !body || !footer) { return }
        const headerHeight = this.$refs.header.scrollHeight
        const bodyHeight = this.$refs.body.scrollHeight
        const footerHeight = this.$refs.footer.scrollHeight
        const heightSum = headerHeight + bodyHeight + footerHeight + 4 // header + body + footer + border
        if (heightSum < this.height) {
          this.contentHeightFixed = heightSum
          this.contentHeight = heightSum
        } else {
          this.contentHeightFixed = this.height
          this.contentHeight = this.height
        }
      })
    },
    setWindowHeight () {
      this.windowHeight = window.innerHeight
    },
    handleResize () {
      // this.setContentHeight()
      this.setWindowHeight()
    }
  }
}
