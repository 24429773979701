import { render, staticRenderFns } from "./Dropdown.vue?vue&type=template&id=5fbeb3c1&scoped=true&"
import script from "./Dropdown.vue?vue&type=script&lang=js&"
export * from "./Dropdown.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fbeb3c1",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Search: require('/vercel/path0/components/utils/Search.vue').default,Check: require('/vercel/path0/components/utils/Check.vue').default,DropdownOverlay: require('/vercel/path0/components/utils/Dropdown/DropdownOverlay.vue').default})
